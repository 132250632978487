import * as _functionBind2 from "function-bind";
var _functionBind = _functionBind2;
try {
  if ("default" in _functionBind2) _functionBind = _functionBind2.default;
} catch (e) {}
import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
import * as _setFunctionLength2 from "set-function-length";
var _setFunctionLength = _setFunctionLength2;
try {
  if ("default" in _setFunctionLength2) _setFunctionLength = _setFunctionLength2.default;
} catch (e) {}
import * as _type2 from "es-errors/type";
var _type = _type2;
try {
  if ("default" in _type2) _type = _type2.default;
} catch (e) {}
import * as _esDefineProperty2 from "es-define-property";
var _esDefineProperty = _esDefineProperty2;
try {
  if ("default" in _esDefineProperty2) _esDefineProperty = _esDefineProperty2.default;
} catch (e) {}
var exports = {};
var bind = _functionBind;
var GetIntrinsic = _getIntrinsic;
var setFunctionLength = _setFunctionLength;
var $TypeError = _type;
var $apply = GetIntrinsic("%Function.prototype.apply%");
var $call = GetIntrinsic("%Function.prototype.call%");
var $reflectApply = GetIntrinsic("%Reflect.apply%", true) || bind.call($call, $apply);
var $defineProperty = _esDefineProperty;
var $max = GetIntrinsic("%Math.max%");
exports = function callBind(originalFunction) {
  if (typeof originalFunction !== "function") {
    throw new $TypeError("a function is required");
  }
  var func = $reflectApply(bind, $call, arguments);
  return setFunctionLength(func, 1 + $max(0, originalFunction.length - (arguments.length - 1)), true);
};
var applyBind = function applyBind() {
  return $reflectApply(bind, $apply, arguments);
};
if ($defineProperty) {
  $defineProperty(exports, "apply", {
    value: applyBind
  });
} else {
  exports.apply = applyBind;
}
export default exports;
export const apply = exports.apply;